
import { defineComponent } from 'vue';
import { useUserFields } from '../composables/useUserFields';
import { useTranslations } from '@/shared/composables/useTranslations';
import { useVersions } from '@/shared/composables/useVersions';

export default defineComponent({
  name: 'RequestUserForm',
  setup() {
    const { t } = useTranslations();
    const { form } = useUserFields();
    const { isPl } = useVersions();

    const updateZip = (value: string) => {
      if (value.length === 2) {
        form.value.zip = `${value}-`;
      } else if (value.length > 2 && !value.includes('-')) {
        form.value.zip = `${value.substring(0, 2)}-${value.substring(2)}`;
      } else {
        form.value.zip = value;
      }
    };

    return {
      t,
      form,
      updateZip,
      isPl,
    };
  },
});
