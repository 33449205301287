
import { defineComponent, PropType, ref, computed, watch } from 'vue';
import { useTranslations } from '@/shared/composables/useTranslations';
import { getConfig } from '@/shared/config';
import { useNumbers } from '@/shared/composables/useNumbers';
import { useDropdownsOptions } from '@/shared/composables/useDropdownsOptions';
import { getPrice, getPriceWithIncurance } from '../../ProductAddToCart/utils/priceCalculation';
import { routes } from '@/shared/constants/routes';
import { Attachment, CartProductWithId } from '@/shared/interfaces/cart';
import { DateRange, DateStringsRange } from '@/shared/interfaces/calendar';
import { useFallbackImage } from '@/shared/composables/useFallbackImage';
import { formatDate, calculateDaysDifference } from '@/shared/utils/formatDate';
import Calendar from '@/shared/components/Calendar/Calendar.vue';
import CalendarInputs from '@/pages/ProductAddToCart/components/CalendarInputs.vue';
import { useProductDetails } from '@/pages/ProductDetails/composables/useProductDetails';
import { initialRange } from '@/shared/constants/initialRange';

const defaultDeliveryOption = 'pickup';

export default defineComponent({
  name: 'CartAttachmentForm',
  components: {
    Calendar,
    CalendarInputs,
  },
  props: {
    productId: { type: Number, required: true },
    values: { type: Object as PropType<CartProductWithId>, required: true },
    index: { type: Number, required: true },
    modelValue: { type: Object as PropType<Attachment>, required: true },
    error: { type: String, default: '' },
  },
  emits: ['update:modelValue', 'deselect'],
  setup(props, { emit }) {
    const { t } = useTranslations();
    const range = ref<DateStringsRange>(initialRange);
    const { product } = useProductDetails(`${props.productId}`);
    const { deliveryOptions, availableBranches } = useDropdownsOptions();
    const { getFallbackImage } = useFallbackImage();
    const { cPerDay, c } = useNumbers();
    const version = getConfig().appVersion;

    const isPickup = computed(() => props.values.type === defaultDeliveryOption);
    const price = computed(() => getPrice(range.value, product.value?.pricing));
    const isOpenEnded = computed(() => props.modelValue.range.end === t('calendar_open_ended'));
    const priceLabel = computed(() =>
      isOpenEnded.value
        ? `${t('calendar_from')} ${formatDate(props.modelValue.range.start)}`
        : cPerDay(getPriceWithIncurance(price.value, Number(product.value?.insurance)))
    );
    const sumLabel = computed(() =>
      isOpenEnded.value
        ? `${t('calendar_from')} ${cPerDay(product.value?.pricing.monthly || 0)}`
        : c(props.values.attachments[props.index].sum)
    );

    const updateValues = (values: DateRange) => {
      emit('update:modelValue', {
        ...props.modelValue,
        range: values,
        sum:
          calculateDaysDifference(range.value) *
          getPriceWithIncurance(price.value, Number(product.value?.insurance)),
      });
    };

    const updateDays = (value: DateStringsRange) => {
      range.value = value;
    };

    watch([price, range], ([newPrice, newRange]) => {
      emit('update:modelValue', {
        ...props.modelValue,
        sum:
          getPriceWithIncurance(newPrice, Number(product.value?.insurance)) *
          calculateDaysDifference(newRange),
      });
    });

    return {
      t,
      getFallbackImage,
      updateValues,
      updateDays,
      cPerDay,
      c,
      availableBranches,
      deliveryOptions,
      isOpenEnded,
      priceLabel,
      isPickup,
      sumLabel,
      product,
      routes,
      price,
      range,
      version,
      calculateDaysDifference,
    };
  },
});
