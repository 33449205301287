import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-46da0644"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-container" }
const _hoisted_2 = { class: "w-full lg:w-1/2 lg:pr-1.5" }
const _hoisted_3 = { class: "flex flex-col lg:flex-row" }
const _hoisted_4 = { class: "flex" }
const _hoisted_5 = { class: "textarea-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseInput = _resolveComponent("BaseInput")!
  const _component_InputGroup = _resolveComponent("InputGroup")!
  const _component_BaseText = _resolveComponent("BaseText")!
  const _component_BaseTextArea = _resolveComponent("BaseTextArea")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_InputGroup, {
        label: _ctx.t('company_label'),
        error: _ctx.form.companyErr
      }, {
        default: _withCtx(() => [
          _createVNode(_component_BaseInput, {
            modelValue: _ctx.form.company,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.company) = $event)),
            name: "company"
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }, 8, ["label", "error"]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_InputGroup, {
          label: _ctx.t('firstname_label'),
          class: "lg:w-1/2 lg:mr-3",
          error: _ctx.form.firstnameErr
        }, {
          default: _withCtx(() => [
            _createVNode(_component_BaseInput, {
              modelValue: _ctx.form.firstname,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.firstname) = $event)),
              name: "firstname"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }, 8, ["label", "error"]),
        _createVNode(_component_InputGroup, {
          label: _ctx.t('lastname_label'),
          class: "lg:w-1/2",
          error: _ctx.form.lastnameErr
        }, {
          default: _withCtx(() => [
            _createVNode(_component_BaseInput, {
              modelValue: _ctx.form.lastname,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.lastname) = $event)),
              name: "lastname"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }, 8, ["label", "error"])
      ]),
      _createVNode(_component_InputGroup, {
        label: _ctx.t('address_label'),
        error: _ctx.form.addressErr
      }, {
        default: _withCtx(() => [
          _createVNode(_component_BaseInput, {
            modelValue: _ctx.form.address,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.address) = $event)),
            name: "address"
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }, 8, ["label", "error"]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_InputGroup, {
          label: _ctx.t('postcode_label'),
          class: "w-1/3 mr-1.5 lg:mr-3",
          error: _ctx.form.zipErr
        }, {
          default: _withCtx(() => [
            (_ctx.isPl)
              ? (_openBlock(), _createBlock(_component_BaseInput, {
                  key: 0,
                  modelValue: _ctx.form.zip,
                  "onUpdate:modelValue": _ctx.updateZip,
                  name: "zip"
                }, null, 8, ["modelValue", "onUpdate:modelValue"]))
              : (_openBlock(), _createBlock(_component_BaseInput, {
                  key: 1,
                  modelValue: _ctx.form.zip,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.zip) = $event)),
                  name: "zip"
                }, null, 8, ["modelValue"]))
          ]),
          _: 1
        }, 8, ["label", "error"]),
        _createVNode(_component_InputGroup, {
          label: _ctx.t('city_label'),
          class: "w-2/3",
          error: _ctx.form.cityErr
        }, {
          default: _withCtx(() => [
            _createVNode(_component_BaseInput, {
              modelValue: _ctx.form.city,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.city) = $event)),
              name: "city"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }, 8, ["label", "error"])
      ]),
      _createVNode(_component_InputGroup, {
        label: _ctx.t('email_label'),
        error: _ctx.form.emailErr
      }, {
        default: _withCtx(() => [
          _createVNode(_component_BaseInput, {
            modelValue: _ctx.form.email,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.form.email) = $event)),
            name: "email"
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }, 8, ["label", "error"]),
      _createVNode(_component_InputGroup, {
        label: _ctx.t('tel_label'),
        error: _ctx.form.telErr
      }, {
        default: _withCtx(() => [
          _createVNode(_component_BaseInput, {
            modelValue: _ctx.form.tel,
            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.form.tel) = $event)),
            name: "tel"
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }, 8, ["label", "error"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_BaseText, {
        variant: "16-17",
        class: "char-limit-text"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t('product_request_char_limit_text')), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_InputGroup, {
        label: _ctx.t('comments_label'),
        error: _ctx.form.commentsErr
      }, {
        default: _withCtx(() => [
          _createVNode(_component_BaseTextArea, {
            modelValue: _ctx.form.comments,
            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.form.comments) = $event)),
            name: "comments",
            class: "comments-input"
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }, 8, ["label", "error"]),
      _createVNode(_component_BaseText, {
        variant: "16-17",
        class: "text-gray-900"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t('product_request_required_text')), 1)
        ]),
        _: 1
      })
    ])
  ]))
}