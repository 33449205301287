
import { defineComponent, PropType, ref, computed, watch } from 'vue';
import { useTranslations } from '@/shared/composables/useTranslations';
import { getConfig } from '@/shared/config';
import { useNumbers } from '@/shared/composables/useNumbers';
import { CartProductWithId, Attachment } from '@/shared/interfaces/cart';
import { DateRange, DateStringsRange } from '@/shared/interfaces/calendar';
import { useDropdownsOptions } from '@/shared/composables/useDropdownsOptions';
import { getPrice, getPriceWithIncurance } from '../../ProductAddToCart/utils/priceCalculation';
import { routes } from '@/shared/constants/routes';
import { useFallbackImage } from '@/shared/composables/useFallbackImage';
import { formatDate, calculateDaysDifference } from '@/shared/utils/formatDate';
import Calendar from '@/shared/components/Calendar/Calendar.vue';
import CalendarInputs from '@/pages/ProductAddToCart/components/CalendarInputs.vue';
import CartAttachmentForm from './CartAttachmentForm.vue';
import { useProductDetails } from '@/pages/ProductDetails/composables/useProductDetails';
import { initialRange } from '@/shared/constants/initialRange';

const defaultDeliveryOption = 'pickup';

export default defineComponent({
  name: 'CartProductForm',
  components: {
    Calendar,
    CalendarInputs,
    CartAttachmentForm,
  },
  props: {
    productId: { type: String, required: true },
    index: { type: Number, required: true },
    modelValue: { type: Object as PropType<CartProductWithId>, required: true },
    error: { type: String, required: false },
  },
  emits: ['update:modelValue', 'deselect'],
  setup(props, { emit }) {
    const { t } = useTranslations();
    const range = ref<DateStringsRange>(initialRange);
    const { getFallbackImage } = useFallbackImage();
    const { cPerDay, c } = useNumbers();
    const { deliveryOptions, availableBranches } = useDropdownsOptions();
    const { product } = useProductDetails(props.productId);
    const version = getConfig().appVersion;

    const updateDays = (value: DateStringsRange) => {
      range.value = value;
    };

    const deselectAtt = (index: number) => {
      const newAttachments = props.modelValue.attachments.filter((_, i) => i !== index);
      emit('update:modelValue', { ...props.modelValue, attachments: newAttachments });
    };

    const update = (val: string, name: string) => {
      emit('update:modelValue', { ...props.modelValue, [name]: val });
    };
    const updateAtt = (val: Attachment, i: number) => {
      const newAttachments = [...props.modelValue.attachments];
      newAttachments[i] = val;
      emit('update:modelValue', { ...props.modelValue, attachments: newAttachments });
    };

    const isPickup = computed(() => props.modelValue.type === defaultDeliveryOption);
    const price = computed(
      () => getPrice(range.value, product.value?.pricing) * Number(props.modelValue.quantity)
    );
    const isOpenEnded = computed(
      () => (props.modelValue.range as DateRange).end === t('calendar_open_ended')
    );
    const priceLabel = computed(() =>
      isOpenEnded.value
        ? `${t('calendar_from')} ${formatDate((props.modelValue.range as DateRange).start)}`
        : cPerDay(
            getPriceWithIncurance(
              price.value,
              Number(product.value?.insurance),
              Number(props.modelValue.quantity)
            )
          )
    );
    const sumLabel = computed(() =>
      isOpenEnded.value
        ? `${t('calendar_from')} ${cPerDay(product.value?.pricing.monthly || 0)}`
        : c(props.modelValue.sum as number)
    );

    watch(
      () => props.modelValue.type,
      () => {
        emit('update:modelValue', { ...props.modelValue, deliveryAddress: '' });
      }
    );

    watch([price, range], ([newPrice, newRange]) => {
      emit('update:modelValue', {
        ...props.modelValue,
        price: price.value,
        sum:
          getPriceWithIncurance(
            newPrice,
            Number(product.value?.insurance),
            Number(props.modelValue.quantity)
          ) * calculateDaysDifference(newRange),
      });
    });

    return {
      t,
      deliveryOptions,
      availableBranches,
      getFallbackImage,
      isPickup,
      update,
      range,
      price,
      cPerDay,
      c,
      routes,
      updateDays,
      priceLabel,
      sumLabel,
      isOpenEnded,
      product,
      updateAtt,
      deselectAtt,
      version,
      calculateDaysDifference,
    };
  },
});
