import { object, string, number, array, date } from 'yup';
import { TranslationFun } from '@/shared/composables/useTranslations';
import { useVersions } from '@/shared/composables/useVersions';
import {
  phoneRegEx,
  postcodeRegEx,
  postcodeRegExPl,
  postcodeRegExLv,
} from '@/shared/constants/regExpressions';

const { isPl, isLv } = useVersions();

const postcodeRegExString = () => {
  switch (true) {
    case isPl:
      return postcodeRegExPl;
    case isLv:
      return postcodeRegExLv;
    default:
      return postcodeRegEx;
  }
};

export const cartValidationShema = (t: TranslationFun) =>
  object({
    company: string(),
    firstname: string().required(t('error_message_firstname')),
    lastname: string(),
    address: string(),
    zip: string().matches(postcodeRegExString(), {
      message: t('error_message_zip'),
      excludeEmptyString: true,
    }),
    city: string(),
    email: string().email(t('error_message_email_valid')).required(t('error_message_email')),
    tel: string().matches(phoneRegEx, {
      message: t('error_message_tel'),
      excludeEmptyString: true,
    }),
    comments: string().max(600, t('error_message_comments')),
    products: array().of(
      object({
        range: object({
          start: date().required(t('error_message_date')),
        }),
        quantity: number(),
        deliveryAddress: string()
          .required(t('error_message_delivery_address'))
          .typeError(t('error_message_delivery_address')),
        type: string(),
        attachments: array().of(
          object({
            range: object({
              start: date().required(t('error_message_date')),
            }),
            id: number(),
            sum: number(),
          })
        ),
        sum: number(),
        price: number(),
      })
    ),
  });
