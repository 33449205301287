import { ref, Ref } from 'vue';
import { injectStrict } from '@/shared/utils/injectStrict';
import { CartRequestKey } from '../constants/injectables';
import { formatApiDate } from '@/shared/utils/formatDate';
import {
  CartRequest,
  Attachment,
  CartProductWithId,
  TransformedCartProduct,
} from '@/shared/interfaces/cart';

interface IRequestResponse {
  isSuccess: Ref<boolean>;
  error: Ref<boolean>;
  isLoading: Ref<boolean>;
  postRequest: (values: CartRequest) => Promise<void>;
}

const transformAttachments = (attachments: Attachment[]) =>
  attachments?.map((attachment) => ({
    date_to: formatApiDate(attachment.range.end),
    date_from: formatApiDate(attachment.range.start),
    id: attachment.id.toString(),
    sum: attachment.sum,
  }));

const calculateProductSum = (attachments: Attachment[], productSum: number) =>
  attachments?.reduce((total, item) => total + item.sum, productSum);

const transformProduct = (product: CartProductWithId) => ({
  id: product.id,
  quantity: product.quantity,
  delivery_address: product.deliveryAddress,
  get_type: product.type,
  date_to: formatApiDate(product.range.end),
  date_from: formatApiDate(product.range.start),
  daily_price: product.price,
  attachments: transformAttachments(product.attachments),
  sum: calculateProductSum(product.attachments, product.sum),
});

const calculateTotalPrice = (transformedProducts: TransformedCartProduct[]) =>
  transformedProducts?.reduce((total, item) => total + item.sum, 0);

export const useCartRequest = (): IRequestResponse => {
  const service = injectStrict(CartRequestKey);
  const isSuccess = ref<boolean>(false);
  const isLoading = ref<boolean>(false);
  const error = ref<boolean>(false);

  const postRequest = async (values: CartRequest) => {
    const { products, ...restValues } = values;

    const transformedProducts = products.map((product) => transformProduct(product));

    const transformedValues = {
      ...restValues,
      products: transformedProducts,
      total_price: calculateTotalPrice(transformedProducts),
    };
    isLoading.value = true;

    try {
      const res = await service.requestCart(transformedValues);
      if (res.status === 200) {
        isSuccess.value = true;
      } else {
        error.value = true;
      }
    } catch (err) {
      error.value = true;
    } finally {
      isLoading.value = false;
    }
  };

  return {
    postRequest,
    isSuccess,
    error,
    isLoading,
  };
};
