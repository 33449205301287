
import { defineComponent, watch } from 'vue';
import { useForm, useFieldArray } from 'vee-validate';
import { routes } from '@/shared/constants/routes';
import { useTranslations } from '@/shared/composables/useTranslations';
import { useOpenable } from '@/shared/composables/useOpenable';
import { USER_FORM_INITIAL_DATA } from './constants/requestInitialData';
import { cartValidationShema } from './composables/cartValidationShema';
import RequestUserForm from './components/RequestUserForm.vue';
import RequestSuccessModal from './components/RequestSuccessModal.vue';
import CartProductForm from './components/CartProductForm.vue';
import { useCartRequest } from './composables/useCartRequest';
import { useSharedCart } from '../ProductAddToCart/composables/useCart';
import { CartRequest, CartProductWithId } from '@/shared/interfaces/cart';
import Error from '@/pages/Error.vue';
import Empty from '@/pages/Empty.vue';

export default defineComponent({
  name: 'Cart',
  components: {
    RequestUserForm,
    CartProductForm,
    RequestSuccessModal,
    Error,
    Empty,
  },
  setup() {
    const { t } = useTranslations();
    const { cart, updateCart, resetDates, count, deleteCart } = useSharedCart();
    const { isOpen: showModal, open: openModal, close: closeModal } = useOpenable();
    const { postRequest, isSuccess, error } = useCartRequest();

    resetDates();

    const { values, meta, errors, validate } = useForm({
      validationSchema: cartValidationShema(t),
      initialValues: { ...USER_FORM_INITIAL_DATA, products: cart.value },
      validateOnMount: false,
    });
    const { remove } = useFieldArray('products');

    const deselectProduct = (index: number) => remove(index);

    const onSubmit = async () => {
      await postRequest(values as CartRequest);
      if (isSuccess.value) {
        openModal();
        deleteCart();
      }
    };

    watch(
      () => values.products,
      async (newProducts) => {
        await validate({ mode: 'silent' });
        updateCart(newProducts as CartProductWithId[]);
      },
      { deep: true }
    );

    return {
      t,
      onSubmit,
      meta,
      values,
      error,
      routes,
      closeModal,
      showModal,
      errors,
      deselectProduct,
      count,
    };
  },
});
