import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withModifiers as _withModifiers, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ac9a8b1a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "min-h-screen bg-white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Error = _resolveComponent("Error")!
  const _component_Empty = _resolveComponent("Empty")!
  const _component_BaseHeading = _resolveComponent("BaseHeading")!
  const _component_BackButton = _resolveComponent("BackButton")!
  const _component_RequestUserForm = _resolveComponent("RequestUserForm")!
  const _component_BaseText = _resolveComponent("BaseText")!
  const _component_CartProductForm = _resolveComponent("CartProductForm")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_BaseContainer = _resolveComponent("BaseContainer")!
  const _component_RequestSuccessModal = _resolveComponent("RequestSuccessModal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.error)
        ? (_openBlock(), _createBlock(_component_Error, { key: 0 }))
        : (!_ctx.count)
          ? (_openBlock(), _createBlock(_component_Empty, { key: 1 }))
          : (_openBlock(), _createBlock(_component_BaseContainer, {
              key: 2,
              class: "bg-white pt-3 lg:pt-4.5"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_BackButton, { class: "back-button" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_BaseHeading, {
                      mobileSmaller: "",
                      size: 4,
                      class: "ml-1 lg:ml-2.5 leading-none"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t('base_back_button_text')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createElementVNode("form", {
                  onSubmit: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["prevent"]))
                }, [
                  _createVNode(_component_RequestUserForm, { class: "mb-6 lg:mb-7.5" }),
                  _createVNode(_component_BaseText, { class: "text-24 lg:text-30 font-bold" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('product_request_product_title')), 1)
                    ]),
                    _: 1
                  }),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.values.products, (product, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: `${product.id}-${index}`,
                      class: "product-container"
                    }, [
                      _createVNode(_component_CartProductForm, {
                        productId: product.id,
                        modelValue: _ctx.values.products[index],
                        "onUpdate:modelValue": ($event: any) => ((_ctx.values.products[index]) = $event),
                        index: index,
                        error: _ctx.errors[`products[${index}].deliveryAddress`],
                        onDeselect: _ctx.deselectProduct
                      }, null, 8, ["productId", "modelValue", "onUpdate:modelValue", "index", "error", "onDeselect"])
                    ]))
                  }), 128)),
                  _createVNode(_component_BaseText, { class: "disclaimer" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('product_request_disclaimer')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_BaseButton, {
                    class: "submit-button",
                    type: "submit",
                    disabled: !_ctx.meta.valid
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('product_request_submit_btn')) + " ", 1),
                      _createVNode(_component_BaseIcon, {
                        icon: "arrow",
                        class: "button-icon"
                      })
                    ]),
                    _: 1
                  }, 8, ["disabled"])
                ], 32)
              ]),
              _: 1
            }))
    ]),
    _createVNode(_component_RequestSuccessModal, {
      opened: _ctx.showModal,
      email: _ctx.values.email,
      onClose: _ctx.closeModal
    }, null, 8, ["opened", "email", "onClose"])
  ]))
}