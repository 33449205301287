export const USER_FORM_INITIAL_DATA = {
  company: '',
  firstname: '',
  lastname: '',
  address: '',
  zip: '',
  city: '',
  email: '',
  tel: '',
  comments: '',
};
