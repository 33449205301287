import { useFormFields, UseFormFields } from '@/shared/composables/useFormFields';

export const useUserFields = (): UseFormFields => {
  const fields = [
    'company',
    'firstname',
    'lastname',
    'address',
    'zip',
    'city',
    'email',
    'tel',
    'comments',
  ];
  const { form } = useFormFields(fields);

  return { form };
};
