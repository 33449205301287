import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3734ea1d"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "main-container"
}
const _hoisted_2 = { class: "form-container" }
const _hoisted_3 = { class: "flex mb-3.5 lg:mb-0" }
const _hoisted_4 = { class: "w-1/2 lg:w-[12.5rem] mr-1.5" }
const _hoisted_5 = { class: "price-container" }
const _hoisted_6 = { class: "price-per-day" }
const _hoisted_7 = { class: "flex justify-end flex-wrap" }
const _hoisted_8 = {
  key: 2,
  class: "attachment-label"
}
const _hoisted_9 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProductImage = _resolveComponent("ProductImage")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BaseText = _resolveComponent("BaseText")!
  const _component_BaseRadioButtonGroup = _resolveComponent("BaseRadioButtonGroup")!
  const _component_BaseDropdownInput = _resolveComponent("BaseDropdownInput")!
  const _component_InputGroup = _resolveComponent("InputGroup")!
  const _component_BaseInput = _resolveComponent("BaseInput")!
  const _component_CalendarInputs = _resolveComponent("CalendarInputs")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_IncrementInput = _resolveComponent("IncrementInput")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_CartAttachmentForm = _resolveComponent("CartAttachmentForm")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.product)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.product.imageUrl)
            ? (_openBlock(), _createBlock(_component_ProductImage, {
                key: 0,
                src: _ctx.product.imageUrl,
                alt: "Product",
                class: "product-image"
              }, null, 8, ["src"]))
            : (_openBlock(), _createBlock(_component_BaseIcon, {
                key: 1,
                icon: _ctx.getFallbackImage(_ctx.product),
                alt: "Product",
                class: "image-placeholder"
              }, null, 8, ["icon"])),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_BaseText, {
              variant: "18-20",
              class: "font-bold"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.product.name), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_BaseText, {
              variant: "18-20",
              class: "font-bold mb-2 lg:mb-3"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.product.model), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_BaseText, { class: "article-number" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(`${_ctx.t('product_request_art_nr')} ${_ctx.product.id}`), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_BaseRadioButtonGroup, {
              class: "radio-buttons",
              modelValue: _ctx.modelValue.type,
              name: `products[${_ctx.index}].type`,
              options: _ctx.deliveryOptions,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = (val) => _ctx.update(val, 'type'))
            }, null, 8, ["modelValue", "name", "options"]),
            (_ctx.isPickup)
              ? (_openBlock(), _createBlock(_component_InputGroup, {
                  key: 0,
                  label: _ctx.t('product_request_pickup_label'),
                  class: "w-full mb-2",
                  error: _ctx.error
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_BaseDropdownInput, {
                      class: "w-full",
                      options: _ctx.availableBranches[_ctx.version],
                      name: `products[${_ctx.index}].deliveryAddress`,
                      modelValue: _ctx.modelValue.deliveryAddress,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = (val) => _ctx.update(val, 'deliveryAddress'))
                    }, null, 8, ["options", "name", "modelValue"])
                  ]),
                  _: 1
                }, 8, ["label", "error"]))
              : (_openBlock(), _createBlock(_component_InputGroup, {
                  key: 1,
                  label: _ctx.t('product_request_delivery_label'),
                  class: "w-full mb-2",
                  error: _ctx.error
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_BaseInput, {
                      class: "input",
                      name: `products[${_ctx.index}].deliveryAddress`,
                      modelValue: _ctx.modelValue.deliveryAddress,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = (val) => _ctx.update(val, 'deliveryAddress'))
                    }, null, 8, ["name", "modelValue"])
                  ]),
                  _: 1
                }, 8, ["label", "error"])),
            _createVNode(_component_Calendar, {
              modelValue: _ctx.modelValue.range,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = (val) => _ctx.update(val, 'range')),
              onDaysChange: _ctx.updateDays
            }, {
              default: _withCtx(({ values, events }) => [
                _createVNode(_component_CalendarInputs, {
                  values: values,
                  events: events
                }, null, 8, ["values", "events"])
              ]),
              _: 1
            }, 8, ["modelValue", "onDaysChange"]),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_IncrementInput, {
                  modelValue: _ctx.modelValue.quantity,
                  name: `products[${_ctx.index}].quantity`,
                  min: 1,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = (val) => _ctx.update(val, 'quantity'))
                }, null, 8, ["modelValue", "name"])
              ]),
              _createVNode(_component_BaseButton, {
                size: 0,
                variant: "secondary",
                class: "remove-button",
                onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('deselect', _ctx.index)))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('product_request_remove_btn')), 1)
                ]),
                _: 1
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              (!_ctx.isOpenEnded)
                ? (_openBlock(), _createBlock(_component_BaseText, {
                    key: 0,
                    variant: "18-20",
                    class: "flex lg:mr-1"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(`${_ctx.calculateDaysDifference(_ctx.range)} ${_ctx.t('product_request_each_day')}`), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_BaseText, {
                variant: "18-20",
                class: "flex"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.priceLabel), 1)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_BaseText, {
                variant: "18-20",
                class: "font-bold"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('product_request_estimated_price')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_BaseText, {
                variant: "18-20",
                class: "font-bold ml-1"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.sumLabel), 1)
                ]),
                _: 1
              })
            ])
          ]),
          (_ctx.product.is_attachment)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.t('product_request_attachment_label')), 1))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.product)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modelValue.attachments, (attachment, i) => {
            return (_openBlock(), _createBlock(_component_CartAttachmentForm, {
              key: attachment.id,
              productId: attachment.id,
              index: i,
              values: _ctx.modelValue,
              modelValue: _ctx.modelValue.attachments[i],
              error: _ctx.error,
              "onUpdate:modelValue": (val) => _ctx.updateAtt(val, i),
              onDeselect: _ctx.deselectAtt
            }, null, 8, ["productId", "index", "values", "modelValue", "error", "onUpdate:modelValue", "onDeselect"]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 64))
}